import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../config';

export const fetchVideos = createAsyncThunk(
  'videos/fetchVideos',
  async ({ clientId, folderId }) => {
    const response = await fetch(`${API_BASE_URL}/clients/${clientId}/${folderId}/`);
    return response.json();
  }
);

const videosSlice = createSlice({
  name: 'videos',
  initialState: {
    videosList: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVideos.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchVideos.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.videosList = action.payload;
      })
      .addCase(fetchVideos.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default videosSlice.reducer; 