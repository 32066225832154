import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { fetchVideos } from '../store/videosSlice';
import Header from './Header';

export default function ClientVideos() {
    const dispatch = useDispatch();
    const { videosList, status, error } = useSelector((state) => state.videos);
    const { clientId, folderId } = useParams();
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [thumbnails, setThumbnails] = useState({});
    const observerRefs = useRef({});
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const generateThumbnail = useCallback(async (video) => {
        if (thumbnails[video.name]) return;

        try {
            // Create elements if they don't exist
            if (!videoRef.current) {
                videoRef.current = document.createElement('video');
            }
            if (!canvasRef.current) {
                canvasRef.current = document.createElement('canvas');
            }

            const videoEl = videoRef.current;
            const canvasEl = canvasRef.current;

            // Set video properties
            videoEl.crossOrigin = 'anonymous';
            videoEl.autoplay = false;
            videoEl.muted = true;
            videoEl.src = video.url;

            // Wait for metadata to load
            await new Promise((resolve, reject) => {
                videoEl.addEventListener('loadedmetadata', resolve, { once: true });
                videoEl.addEventListener('error', reject, { once: true });
            });

            // Set canvas size based on video dimensions
            canvasEl.width = videoEl.videoWidth;
            canvasEl.height = videoEl.videoHeight;

            // Set time to 0 seconds
            videoEl.currentTime = 0;

            // Wait for seek to complete
            await new Promise((resolve) => {
                videoEl.addEventListener('seeked', resolve, { once: true });
            });

            // Draw video frame on canvas
            const ctx = canvasEl.getContext('2d');
            ctx.drawImage(videoEl, 0, 0, canvasEl.width, canvasEl.height);

            // Get thumbnail as data URL
            const thumbnailUrl = canvasEl.toDataURL('image/jpeg', 0.8);

            // Update thumbnails state
            setThumbnails(prev => ({
                ...prev,
                [video.name]: thumbnailUrl
            }));

            // Reset video source
            videoEl.src = '';

        } catch (error) {
            console.error('Error generating thumbnail:', error);
            setThumbnails(prev => ({
                ...prev,
                [video.name]: null
            }));
        }
    }, [thumbnails]);

    useEffect(() => {
        dispatch(fetchVideos({ clientId, folderId }));
    }, [dispatch, clientId, folderId]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        const videoName = entry.target.dataset.videoName;
                        const video = videosList.find(v => v.name === videoName);
                        if (video) {
                            generateThumbnail(video);
                            observer.unobserve(entry.target);
                        }
                    }
                });
            },
            {
                rootMargin: '50px 0px',
                threshold: 0.1
            }
        );

        // Cleanup previous observers
        Object.values(observerRefs.current).forEach(ref => {
            if (ref) observer.unobserve(ref);
        });
        observerRefs.current = {};

        return () => {
            observer.disconnect();
            if (videoRef.current) {
                videoRef.current.src = '';
            }
        };
    }, [videosList, generateThumbnail]);

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <Header />
            <div className="main-content">
                <div className="container mt-5">
                    <Link to={`/client/${clientId}/folders`} className="btn btn-outline-primary mb-3">
                        <i className="bi bi-arrow-left"></i> Back to Folders
                    </Link>
                    <h2 className="mb-4">Videos for {clientId.charAt(0).toUpperCase() + clientId.slice(1)} - {folderId.toString()}</h2>
                    <div className="row">
                        {videosList.map(video => (
                            <div key={video.name} className="col-md-4 mb-4">
                                <div className="card h-100 shadow hover-card">
                                    <div 
                                        className="video-thumbnail"
                                        ref={el => {
                                            if (el) observerRefs.current[video.name] = el;
                                        }}
                                        data-video-name={video.name}
                                    >
                                        {thumbnails[video.name] ? (
                                            <img 
                                                src={thumbnails[video.name]} 
                                                alt="Video thumbnail" 
                                                className="card-img-top"
                                            />
                                        ) : (
                                            <div className="thumbnail-placeholder">
                                                <div className="play-icon-overlay">
                                                    <i className="bi bi-play-circle-fill"></i>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <div className="card-body d-flex flex-column">
                                        <h5 className="card-title text-truncate">{video.name.split('/').pop()}</h5>
                                        <button 
                                            className="btn btn-primary mt-auto"
                                            onClick={() => setSelectedVideo(video)}
                                            data-bs-toggle="modal" 
                                            data-bs-target="#videoModal"
                                        >
                                            <i className="bi bi-play-circle me-2"></i>
                                            Play Video
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Video Modal */}
                <div className="modal fade" id="videoModal" tabIndex="-1" aria-labelledby="videoModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="videoModalLabel">
                                    {selectedVideo?.name.split('/').pop()}
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body p-0">
                                {selectedVideo && (
                                    <video 
                                        className="w-100" 
                                        controls 
                                        autoPlay
                                        src={selectedVideo.url}
                                    >
                                        Your browser does not support the video tag.
                                    </video>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                <p className="mb-0">&copy; 2024 Replay Edge</p>
            </footer>
        </>
    );
}
