import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store/store';
import Videos from './components/Videos';
import ClientFolders from './components/ClientFolders';
import ClientVideos from './components/ClientVideos';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import './App.css';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={
            <PrivateRoute>
              <Videos />
            </PrivateRoute>
          } />
          <Route path="/client/:clientId/folders" element={
            <PrivateRoute>
              <ClientFolders />
            </PrivateRoute>
          } />
          <Route path="/clients/:clientId/folders/:folderId/videos" element={
            <PrivateRoute>
              <ClientVideos />
            </PrivateRoute>
          } />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
