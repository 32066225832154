import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { fetchFolders } from '../store/clientsSlice';
import Header from './Header';

export default function ClientFolders() {
    const dispatch = useDispatch();
    const { folders, status, error } = useSelector((state) => state.clients);
    const { clientId } = useParams();

    useEffect(() => {
        dispatch(fetchFolders(clientId));
    }, [dispatch, clientId]);

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <Header />
            <div className="container mt-5">
                <Link to="/" className="btn btn-outline-primary mb-3">
                    <i className="bi bi-arrow-left"></i> Back to Clients
                </Link>
                <h2 className="mb-4">{clientId.charAt(0).toUpperCase() + clientId.slice(1)} Folders</h2>
                <div className="row">
                    {folders.map(folder => (
                        <div key={folder} className="col-md-4 mb-4">
                            <Link to={`/clients/${clientId}/folders/${folder}/videos`} className="text-decoration-none">
                                <div className="card h-100 shadow-sm hover-effect">
                                    <div className="card-body d-flex flex-column align-items-center">
                                        <i className="bi bi-folder fs-1 mb-3"></i>
                                        <h5 className="card-title text-center">{folder}</h5>
                                        <p className="card-text text-center text-muted">View videos</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <footer className="bg-dark text-white text-center py-3 mt-5 fixed-bottom">
                <p className="mb-0">&copy; 2024 Replay Edge</p>
            </footer>
        </>
    );
}
