import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { API_BASE_URL } from '../config';

export const fetchClients = createAsyncThunk(
  'clients/fetchClients',
  async () => {
    const response = await fetch(`${API_BASE_URL}/clients/`);
    return response.json();
  }
);

export const fetchFolders = createAsyncThunk(
  'clients/fetchFolders',
  async (clientId) => {
    const response = await fetch(`${API_BASE_URL}/clients/${clientId}/`);
    return response.json();
  }
);

const clientsSlice = createSlice({
  name: 'clients',
  initialState: {
    clientsList: [],
    folders: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.clientsList = action.payload;
      })
      .addCase(fetchClients.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchFolders.fulfilled, (state, action) => {
        state.folders = action.payload;
      });
  },
});

export default clientsSlice.reducer; 