import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchClients } from '../store/clientsSlice';
import Header from './Header';

export default function Videos() {
    const dispatch = useDispatch();
    const { clientsList, status, error } = useSelector((state) => state.clients);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchClients());
        }
    }, [status, dispatch]);

    if (status === 'loading') {
        return <div>Loading...</div>;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    return (
        <>
            <Header />
            <div className="container mt-5">
                <div className="row">
                    {clientsList.map(client => (
                        <div key={client} className="col-md-4 mb-4">
                            <Link to={`/client/${client}/folders`} className="text-decoration-none">
                                <div className="card h-100 shadow-sm hover-effect">
                                    <div className="card-body d-flex flex-column align-items-center">
                                        <img 
                                            src={`/client_logos/${client.toLowerCase()}.png`} 
                                            className="card-img-top client-logo mb-3" 
                                            alt={`${client} logo`}
                                        />
                                        <h5 className="card-title text-center">{client}</h5>
                                        <p className="card-text text-center text-muted">View {client}'s folders</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
            <footer className="bg-dark text-white text-center py-3 mt-5 fixed-bottom">
                <p className="mb-0">&copy; 2024 Replay Edge</p>
            </footer>
        </>
    );
}
